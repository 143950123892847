import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import { withUserContext } from '../../../contexts/UserContext';
import { iconList, syncLicensorConfig, syncLicensorList, selectOptionList, selectLicensorType, syncIconList, CueSheetPublisherConfig, CueSheetPublisherList, syncLicensorFlagConfig, mainTitlesyncLicensorConfig, selectMaintitleOptionList } from './Config';
import Details from './Details';
import MasterLicensor from './MasterLicensor/MasterLicensor';
import SyncLicensor from './SyncLicensor/SyncLicensor';
import Popover from '@material-ui/core/Popover';
import { optionalRightsConfig, optionalRightsList } from './SyncLicensor/Config';
import * as Constants from '../../../constants/constants';
import clearTrackService from '../../../services/service';
import notificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import { selectSongList, Licensor, licensorRightsConfig, clearanceRecordPermissionsConfig } from './Config';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import { CircularProgress } from '@material-ui/core';

let permissionViewSongInformationAndPerformerName, permissionAddChangeSong, permissionEditASong, songModalPermissions, restrictMessage, songIndex;
export default withUserContext(class CompositionFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingCompositionFilesDetails: false,
            showIconChange: false,
            config: iconList,
            setPopover: null,
            selectSongList: selectSongList,
            status: [],
            fieldChanged: false,
            showEditSongModal: false,
            showEditClearanceModal: false,
            showNewSongModal: false,
            licensorsInfo: this.props?.licensorsInfo,
            clearneceLicenserInfo: this.props?.clearneceLicenserInfo,
            syncLicensorConfig: syncLicensorConfig,
            mode: this.props?.mode,
            clearance_divisionId: null,
            clearenceId: null,
            showError: false,
            selectedSong: this.props?.licensorsInfo?.[0]?.songs_list?.[0],
            totalInitFee: 0,
            syncInitFee: 0,
            masterInitFee: 0,
            restrictedPromo: false,
            limitedPromo: false,
            selectOptionList: !this.props?.isMainTitle ? [...selectOptionList] :  [...selectMaintitleOptionList],
            totalMasterLicensor: 0,
            totalSyncLicensor: 0,
            isSubmit: false,
            isAllowBtnLoader: false,
            isLimitedBtnLoader: false,
            showCalcFeesError: false,
            restrictSong: false,
            clearanceRecordData: [],
            message: null,
            deleteClearanceRecord:false,
            deleteClearanceRecordLoading:false,
            deleteTitle: "Delete Clearance Record",
            deleteMessage:"Are you sure you want to delete this record?",
            deleteMainTitleRecord:false,
            deleteMainTitle:"Delete Main Title Record"
        }
    }

    componentDidMount() {
        // if (!this.props?.isMainTitle) {
        //     this.getPermission(this.props?.permissionArrClearanceRecords)
        // } else {
        //     this.getPermission(this.props?.permissionArrMainTitile)
        // }
        this.getStatusData();
        this.getClearanceData(this.props?.clearenceId)
        let clearanceInfo = this.state?.clearneceLicenserInfo?.[0]
        let totalInitFee = 0;
        let syncInitFee = 0;
        let masterInitFee = 0;
        let  syncMasterInitFee=0;
        let total = 0;
        let totalMasterLicensor = clearanceInfo?.licensors_list?.filter(o => o?.licensor_type === "M")?.length;
        let totalSyncLicensor = clearanceInfo?.licensors_list?.filter(o => o?.licensor_type === "S")?.length;
        let selectOptionList = this.filterSelectOptionList(totalMasterLicensor, totalSyncLicensor);
        let newfee = this.state?.clearneceLicenserInfo?.[0]?.licensors_list?.[0]?.rights?.[0].fee
        clearanceInfo?.licensors_list?.map(item => item?.rights?.map(rightData => {
            if (rightData?.is_initial === 1) {
                totalInitFee = totalInitFee + rightData?.fee;
                if (item?.licensor_type === "M") {
                    return masterInitFee = masterInitFee + rightData?.fee;
                }
                if (item?.licensor_type === "S") {
                    return syncInitFee = syncInitFee + rightData?.fee;
                }
                if (item?.licensor_type === "SM"){
                    return syncMasterInitFee = syncMasterInitFee + rightData?.fee
                }
            }
        }))
        let masterInitfee1 = (syncMasterInitFee)/2  + masterInitFee;
        let syncInitFee1 = (syncMasterInitFee)/2 + syncInitFee;   
        totalInitFee = syncInitFee1+ masterInitfee1;
        this.setState({ totalInitFee: totalInitFee, syncInitFee: syncInitFee1, masterInitFee: masterInitfee1, selectOptionList, totalMasterLicensor, totalSyncLicensor })
        this.props?.notificationMessage(null);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.licensorsInfo !== prevProps.licensorsInfo) {
            this.setState({ licensorsInfo: this.props.licensorsInfo });
        }
        if (this.props.clearneceLicenserInfo !== prevProps.clearneceLicenserInfo) {
            this.setState({ clearneceLicenserInfo: this.props.clearneceLicenserInfo });
            if (this.props?.isMainTitle) {
                let syncLicensorConfig = { ...this.state.syncLicensorConfig };
                syncLicensorConfig.primaryKey = "main_title_check_request_id";
                this.setState({ syncLicensorConfig: syncLicensorConfig })
            }
        }
        if (this.props.mode !== prevProps.mode) {
            this.setState({ mode: this.props.mode });
        }
        if (this.props.clearenceId !== prevProps.clearenceId) {
            this.getClearanceData(this.props?.clearenceId)
        }
    }
    filterSelectOptionList(totalMasterLicensor, totalSyncLicensor) {

        let selectOptionList = [...this.state?.selectOptionList]
        if (totalMasterLicensor >= 1 && totalSyncLicensor == 1) {
            selectOptionList = [...selectOptionList, {
                label: "Calc.Sync fees based on Master",
                value: 7,
                type: "M"
            }]
        }
        if (totalMasterLicensor == 1 && totalSyncLicensor >= 1) {
            selectOptionList = [...selectOptionList, {
                label: "Calc.Master fee based on Sync",
                value: 8,
                type: "S"
            }]
        }
        if (totalMasterLicensor >= 1 && totalSyncLicensor > 1) {
            selectOptionList = [...selectOptionList, {
                label: "Calc.Sync & co-pub fees based on Master",
                value: 9,
                type: "M"
            }]
        }
        if (totalMasterLicensor > 1) {
            selectOptionList = [...selectOptionList, {
                label: "Calc.Co-Master fees based on Current Master",
                value: 10,
                type: "M"
            }]
        }
        if (totalSyncLicensor > 1) {
            selectOptionList = [...selectOptionList, {
                label: "Calc.Co-Pub fees based on Current Sync Licensor",
                value: 11,
                type: "S"
            }]
        }

        if (totalMasterLicensor > 1 && totalSyncLicensor >= 1) {
            selectOptionList = [...selectOptionList, {
                label: "Calc.Master & Co-Master fees based on Sync Licensor",
                value: 12,
                type: "S"
            }]
        }


        return selectOptionList;

    }
    // getPermission(permissions) {
    //     if (permissions.length > 0) {
    //         permissionViewSongInformationAndPerformerName = permissions.filter((obj, index) => obj.permission_name === "Can view song information and Performer Name");
    //         permissionViewSongInformationAndPerformerName = permissionViewSongInformationAndPerformerName.length > 0 ? true : false;
    //         if (!this.props?.isMainTitle) {
    //             permissionAddChangeSong = permissions.filter((obj, index) => obj.permission_name === "Can add/change a Clearance Record's song");
    //             permissionAddChangeSong = permissionAddChangeSong.length > 0 ? true : false;
    //         } else {
    //             permissionAddChangeSong = permissions.filter((obj, index) => obj.permission_name === "Can edit/remove/change Main Title's song");
    //             permissionAddChangeSong = permissionAddChangeSong.length > 0 ? true : false;
    //         }

    //         permissionEditASong = permissions.filter((obj, index) => obj.permission_name === "Can edit a Song");
    //         permissionEditASong = permissionEditASong.length > 0 ? true : false;
    //     }
    // }
    getStatusData = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=STATUS&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.status_id, label: item.status }));
                this.setState({ status: formattedList }, () => { console.log(this.state.status) });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    // this.setState({ isFetchingClearanceDetails: false });
                })
    }
    removeSong = () => {

        if (this.props?.licensorsInfo[0]?.songs_list.length > 1) {
            let songDeleteQueryStringParam = !this.props?.isMainTitle ? `clearance_song_id=${this.state.selectedSong?.clearance_song_id}` :
                `main_title_song_id=${this.state.selectedSong?.main_title_song_id}`
            console.log("song", songDeleteQueryStringParam)
            clearTrackService.deleteParam(Constants.ClearTrackServiceBaseUrl + `/clearanceMainTitleSong?${songDeleteQueryStringParam}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                .then(response => {
                    if (!response.data.error) {
                        this.props?.notificationComponent(true, "success");

                        this.props?.toggleRefreshPage('EpisodeClearance')
                        // console.log(response);
                    } else {

                        this.props?.notificationComponent(true, "fail");
                    }
                }, (err) => {
                    console.log("File Delete Failed:" + err);
                    this.props?.notificationComponent(true, "fail");


                });
        } else {

            this.setState({ showError: true });

        }
    }
    fieldChanged = (value, mode = "error") => {
        console.log("from fieldChanged");
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value }, () => { console.log("from fieldChanged", this.state.fieldChanged); });
            case "error": return this.setState({ showError: value }, () => { console.log("from showError", this.state.showError); });
            // case "success": return this.setState({ showSuccess: value });
            // case "fail": return this.setState({ showFail: value });
        }
    }

    onConfirm = () => {
        if(this.state?.deleteClearanceRecord){
            this.deleteNoBatchClearanceRecord();
            this.setState({
                showError: false
            })
        }else if(this.state?.deleteMainTitleRecord){
            this.deleteMainTitleDetails();
            this.setState({
                showError: false
            })
        }else{
        this.setState({
            fieldChanged: false,
            showError: false
        })
    }
    }
    onCancel = () => {
        this.setState({
            showError: false,deleteClearanceRecord:false
        })
    }

    onRestrictCancel = () => {
        let licensorsInfo = this.state.licensorsInfo
        licensorsInfo[0].songs_list[songIndex].song_restricted_loading = false
        this.setState({ licensorsInfo: licensorsInfo, restrictSong: false })
    }

    handleIconChange = () => {
        this.setState({ showIconChange: true })
    }
    handleShowRestrictPromo = () => {
        this.setState({ restrictedPromo: true })
    }
    handleHideRestrictPromo = () => {
        this.setState({ restrictedPromo: false })
    }
    handleClick = (event) => {
        this.setState({ setPopover: event.currentTarget })
    };

    handleClose = () => {
        this.setState({ setPopover: null })
    };
   
    handleDeleteConfirmation = () => {
        if (this.props?.clearenceId) {
            this.setState({ showError: true,deleteClearanceRecord:true});
        }else{
            this.setState({ showError: true,deleteMainTitleRecord:true});
        }
    }

    deleteMainTitleDetails(){
        console.log('deleteing Main title');
        this.deleteMainTitleRecord();
    }

    deleteMainTitleRecord() {
        if(this.props.licensorsInfo?.[0]?.main_title_id  || this.props?.mainTitleId){
        this.setState({deleteClearanceRecordLoading: true});
        clearTrackService.deleteParam(Constants.ClearTrackServiceBaseUrl + `/mainTitle?main_title_id=${this.props.licensorsInfo?.[0]?.main_title_id  || this.props?.mainTitleId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                this.setState({deleteClearanceRecord: false,deleteClearanceRecordLoading:false})
                if (response?.data?.error) {
                    this.props?.notificationMessage("Failed to delete MainTitle Record");
                    this.props?.notificationComponent(true, "fail");
                }
                else {
                    console.log(response);
                    if(response?.data[0]?.message==='FAILED'){
                        this.props?.notificationMessage("Unable to Delete the Main Title Record due to Batches available");
                        this.props?.notificationComponent(true, "fail");
                    }else{
                    this.props?.notificationMessage("Main Title Record Deleted Successfully");
                    this.props?.notificationComponent(true, "success");
                    this.props.handleChangePage();
                    }
                }
                setTimeout(() => {
                    this.props?.notificationMessage(null);
                 }, 5000);
            },
                (err) => {
                    this.props?.notificationComponent(true, "fail");
                    this.setState({deleteClearanceRecord: false,deleteClearanceRecordLoading:false})
                })
            }
    }

    deleteNoBatchClearanceRecord() {
        if(this.state?.clearanceRecordData?.clearance_id){
        this.setState({deleteClearanceRecordLoading: true})
        clearTrackService.deleteParam(Constants.ClearTrackServiceBaseUrl + `/clearance?clearance_id=${this.state?.clearanceRecordData?.clearance_id}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                this.setState({deleteClearanceRecord: false,deleteClearanceRecordLoading:false})
                if (response.data.error) {
                    this.props?.notificationMessage("Failed to delete Clearance Record");
                    this.props?.notificationComponent(true, "fail");
                }
                else {
                    if (response?.data[0]?.message === 'HASBATCH') {
                        this.props?.notificationMessage("Unable to Delete the Clearance Record due to Batches available");
                        this.props?.notificationComponent(true, "fail");
                    } else if (response?.data[0]?.message === 'FAILED') {
                        this.props?.notificationMessage("Unable to Delete the Clearance Record");
                        this.props?.notificationComponent(true, "fail");
                    }
                    else {
                        this.props?.notificationMessage("Clearance Record Deleted Successfully");
                        this.props?.notificationComponent(true, "success");
                        this.props.handleChangePage();
                    }
                }
            },
                (err) => {
                    this.props?.notificationComponent(true, "fail");
                    this.setState({deleteClearanceRecord: false,deleteClearanceRecordLoading:false})
                })
            }
    }

    handleSongOptionClick = (selectedOption) => {
        this.setState({ setPopover: null })
        if (selectedOption === "Edit this Song" && songModalPermissions?.view === 1) {
            this.setState({ showEditSongModal: true })
        } else if (selectedOption === "Add Song to this record") {
            this.setState({ showEditClearanceModal: true, clearenceId: this.state.licensorsInfo[0]?.clearance_id, clearance_divisionId: this.state.licensorsInfo[0]?.division_id })
        } else if (selectedOption === "Remove this song from record") {
            this.removeSong()
        }
    }

    handleHideShowEditSongModal = () => {
        this.setState({ showEditSongModal: false })
    }

    handleSongSelection = (song) => {
        this.setState({ selectedSong: song })
    }
    addNewLicensorInfo = () => {
        let newLicensor  = JSON.parse(JSON.stringify(Licensor))
        let pendingStatusId = this.state?.status?.filter(o => o?.label?.toLowerCase() === "pending")?.[0]?.value;
        newLicensor.editing = true;
        newLicensor.status_id = pendingStatusId;
        if(!this.props?.defaultRights){
            newLicensor.rights = [...licensorRightsConfig]
        }
        else {
            newLicensor.rights = [...licensorRightsConfig]
            newLicensor.rights[0].right_id = this.props?.defaultRights?.initial_right_id;
            newLicensor.rights[0].right_abbr = this.props?.defaultRights?.initial_right_abbr;
            newLicensor.rights[0].term_id = this.props?.defaultRights?.initial_term_id;
            newLicensor.rights[0].territory_id = this.props?.defaultRights?.initial_territory_id;
            newLicensor.rights[0].fee = null;

            newLicensor.rights[1].right_id = this.props?.defaultRights?.alt_initial_right_id;
            newLicensor.rights[1].right_abbr = this.props?.defaultRights?.alt_initial_right_abbr;
            newLicensor.rights[1].term_id = this.props?.defaultRights?.alt_initial_term_id;
            newLicensor.rights[1].territory_id = this.props?.defaultRights?.alt_initial_territory_id;
            newLicensor.rights[1].fee = null
        }

        let clearneceLicenserInfo = JSON.parse(JSON.stringify(this.state?.clearneceLicenserInfo));
        console.log(clearneceLicenserInfo);
        if (clearneceLicenserInfo?.length === 0) {
            clearneceLicenserInfo = [{
                'licensors_list': [newLicensor],
                'season_id': this.props?.seasonId,
                'show_id': this.props?.showId,
                'show_season_id': this.props?.showSeasonId
            }]
            if (this.props?.isMainTitle) {
                clearneceLicenserInfo.main_title_id = this.props?.mainTitleId
            } else {
                clearneceLicenserInfo.clearance_id = this.props?.clearenceId
            }
        } else {
            clearneceLicenserInfo[0].licensors_list.unshift(newLicensor);
        }
        // this.setState(prevState => {
        //     let temp = {
        //         ...prevState,
        //         clearneceLicenserInfo: [...prevState.clearneceLicenserInfo]
        //       }
        //       temp.clearneceLicenserInfo[0].licensors_list = [Licensor,...temp.clearneceLicenserInfo.licensors_list]
        //       return temp;
        // },()=>console.log("licensorsInfo",this.state.clearneceLicenserInfo))
        this.setState({ clearneceLicenserInfo: clearneceLicenserInfo });


    }

    setChkReqOnMainShareFeeChanged(licensorData, chkKey, fee) {
        let checkRequestData = [...licensorData?.[chkKey]] || [];
        if (checkRequestData?.length > 0) {
            checkRequestData = checkRequestData?.map(o => {
                if (o?.lic_per) {
                    let initFee = this.calculateLicShareAmount(o?.lic_per, null, null, fee);
                    o.init_fee = !isNaN(initFee) ? initFee : o.init_fee
                }
                return o;
            });
            return checkRequestData;
        }
        else{
            return [];
        }

    }
    calculateFees(licensorList, licensorId,chkKey) {
        let licKey = this.props?.isMainTitle ? "main_title_licensor_id" : "clearance_licensor_id"
        let currentLicensor = this.state?.clearneceLicenserInfo?.[0]?.licensors_list?.filter(o => o?.[licKey] == licensorId)?.[0] || [];
        if (currentLicensor?.length !== 0) {
            let updatedLic = licensorList?.reduce((updatedLic, licensor) => {
                if (licensor?.[licKey] !== licensorId) {
                    if (parseInt(licensor?.share) == 0 || parseInt(licensor?.share) == 100) {
                        let mainFee = currentLicensor?.rights?.[0]?.fee;
                        licensor.rights[0].fee = mainFee;
                        licensor[chkKey] = this.setChkReqOnMainShareFeeChanged(licensor, chkKey, mainFee);
                        licensor.isCalculatedfees = true

                    }
                    else if (parseInt(licensor?.share) > 0 && parseInt(licensor?.share) < 100) {

                        let totalFee = !isNaN((parseFloat(currentLicensor?.rights?.[0]?.fee) / parseFloat(currentLicensor?.share)) * 100) ? (parseFloat(currentLicensor?.rights?.[0]?.fee) / parseFloat(currentLicensor?.share)) * 100  : 0;
                        let initFee = !isNaN((totalFee * parseFloat(licensor?.share)) / 100) ? (totalFee * parseFloat(licensor?.share)) / 100 : 0;
                        licensor.rights[0].fee = initFee;
                        licensor[chkKey] = this.setChkReqOnMainShareFeeChanged(licensor, chkKey, initFee);
                        licensor.isCalculatedfees = true
                    }
                    else {

                    }
                    return [...updatedLic, licensor]
                }
                return updatedLic;
            }, []);
            return updatedLic;
        }
    }
    calcFeesBasedOnSyncMaster(label, type, licensorId) {
        let chkKey = this.props?.isMainTitle ? "main_title_check_request" : "clearance_check_request";
        let  calc_sync_fee_based_on_master=0,
        calc_master_fee_based_on_sync=0,
        calc_sync_co_pub_fee_based_on_master=0,
        calc_co_master_fee_based_on_current_master=0,
        calc_co_pub_fee_based_on_current_sync=0,
        calc_master_co_master_based_on_current_sync=0;
       let clearanceInfo =  this.state?.clearneceLicenserInfo?.[0];
       let editing = [];
        let isCheckDateOrCheckNumAvl = clearanceInfo?.licensors_list?.reduce((checkDateArr, licensor) => {
            if(licensor?.editing){
                editing.push(true)
            }
            if (licensor?.licensor_type === type) {
                let chkPaid = licensor?.[chkKey]?.find(item => item?.check || item?.date_paid);
                if (chkPaid) {
                    return [...checkDateArr, chkPaid];
                }
            }
            return checkDateArr;
        }, []);

        // console.log(isCheckDateOrCheckNumAvl, "isCheckDateOrCheckNumAvl")
        if (isCheckDateOrCheckNumAvl?.length > 0 || editing?.length > 0) {
            this.setState({showCalcFeesError:true})
            this.setTimeout(()=>{this.setState({showCalcFeesError:false})},3000)
            return;
        }
        
        let updatedLic;
        switch (label) {
            case "Calc.Sync fees based on Master":{

                let licensorList = clearanceInfo?.licensors_list?.filter(o => o.licensor_type === "S");
                 updatedLic = this.calculateFees(licensorList, licensorId,chkKey);
                 calc_sync_fee_based_on_master= 1;
                break;
            }
            case "Calc.Master fee based on Sync":{

                let licensorList = clearanceInfo?.licensors_list?.filter(o => o.licensor_type === "M");
                 updatedLic = this.calculateFees(licensorList, licensorId,chkKey);
                 calc_master_fee_based_on_sync = 1;
                break;
            }
            case "Calc.Sync & co-pub fees based on Master":{

                let licensorList = clearanceInfo?.licensors_list?.filter(o => o.licensor_type === "S");
                 updatedLic = this.calculateFees(licensorList, licensorId,chkKey);
                 calc_sync_co_pub_fee_based_on_master =1;
                break;
            }
            case "Calc.Co-Master fees based on Current Master":{

                let licensorList = clearanceInfo?.licensors_list?.filter(o => o.licensor_type === "M");
                 updatedLic = this.calculateFees(licensorList, licensorId,chkKey);
                 calc_co_master_fee_based_on_current_master = 1;
                break;
            }
            case "Calc.Co-Pub fees based on Current Sync Licensor":{

                let licensorList =clearanceInfo?.licensors_list?.filter(o => o.licensor_type === "S");
                 updatedLic = this.calculateFees(licensorList, licensorId,chkKey);
                 calc_co_pub_fee_based_on_current_sync=1;
                
                break;
            }
            case "Calc.Master && Co-Master fees based on Sync Licensor":{

                let licensorList = clearanceInfo?.licensors_list?.filter(o => o.licensor_type === "M");
                 updatedLic = this.calculateFees(licensorList, licensorId,chkKey);
                 calc_master_co_master_based_on_current_sync=1;
                
                break;
            }
            default:
                break;
        }
         console.log("licensor fee updated", updatedLic);
         let entity = this.props?.isMainTitle ? "MAIN_TITLE" : "CLEARANCE"
         let mainKey = this.props?.isMainTitle ? 'main_title_id':"clearance_id";
         let licKey = this.props?.isMainTitle ? "main_title_licensor_id" : "clearance_licensor_id"
         let licChkId = this.props?.isMainTitle ? "main_title_check_request_id" : "clearance_check_request_id"
         let rightsKey = this.props?.isMainTitle ? "main_title_licensor_rights_id" : "clearance_licensor_rights_id"
         let formattedLic = updatedLic?.map(i=>{
            let chkObj = i?.[chkKey]?.map(o=>{
                return  {
                    [licChkId]: o?.[licChkId],
                    lic_per: o?.lic_per,
                    share: o?.share,
                    init_fee: o?.init_fee                    
                  }
            })
            return {
                [licKey]:i?.[licKey],
                share: i?.share,
                status_id:i?.status_id,
                rights: [{
                  [rightsKey]: i?.rights?.[0]?.[rightsKey],            
                  fee: i?.rights?.[0]?.fee
                }],                
                [chkKey]: [...chkObj]
             }
            }
        )
        if(formattedLic?.length > 0){
            let postJSON = {
                entity,
                [mainKey]: clearanceInfo?.[mainKey],
                show_season_id: clearanceInfo?.show_season_id,
                show_id: clearanceInfo?.show_id,
                season_id: clearanceInfo?.season_id,
                calc_sync_fee_based_on_master,
                calc_master_fee_based_on_sync,
                calc_sync_co_pub_fee_based_on_master,
                calc_co_master_fee_based_on_current_master,
                calc_co_pub_fee_based_on_current_sync,
                calc_master_co_master_based_on_current_sync,
                licensors_list:formattedLic
             }
             console.log(postJSON,"postJSON")
             this.postLicensor(postJSON);
        }
        
    }

    changeStatusOfLicensors = (type, name) => {
        let clearanceInfo =  this.state?.clearneceLicenserInfo?.[0];
        let mainKey = this.props?.isMainTitle ? 'main_title_id':"clearance_id";
        let formattedLic = clearanceInfo?.licensors_list
        let licKey = this.props?.isMainTitle ? "main_title_licensor_id" : "clearance_licensor_id"
        let licChkId = this.props?.isMainTitle ? "main_title_check_request_id" : "clearance_check_request_id"
        let rightsKey = this.props?.isMainTitle ? "main_title_licensor_rights_id" : "clearance_licensor_rights_id"
        let chkKey = this.props?.isMainTitle ? "main_title_check_request" : "clearance_check_request";
        let licensorList = []
      
         formattedLic = formattedLic?.map(i=>{
            let chkObj = i?.[chkKey]?.map(o=>{
                return  {
                    [licChkId]: o?.[licChkId],
                    lic_per: o?.lic_per,
                    share: o?.share,
                    init_fee: o?.init_fee                    
                  }
            })
            return {
                [licKey]:i?.[licKey],
                share: i?.share,
                status_id:type,
                status:name,
                rights: [{
                  [rightsKey]: i?.rights?.[0]?.[rightsKey],            
                  fee: i?.rights?.[0]?.fee
                }],                
                [chkKey]: [...chkObj]
             }
            }
        )
        if(formattedLic?.length > 0){
            let postJSON = {
                entity : this.props?.isMainTitle ? "MAIN_TITLE" : "CLEARANCE",
                [mainKey]: clearanceInfo?.[mainKey],
                show_season_id: clearanceInfo?.show_season_id,
                show_id: clearanceInfo?.show_id,
                season_id: clearanceInfo?.season_id,
                calc_sync_fee_based_on_master: clearanceInfo?.calc_sync_fee_based_on_master,
                calc_master_fee_based_on_sync: clearanceInfo?.calc_master_fee_based_on_sync,
                calc_sync_co_pub_fee_based_on_master: clearanceInfo?.calc_sync_co_pub_fee_based_on_master,
                calc_co_master_fee_based_on_current_master: clearanceInfo?.calc_co_master_fee_based_on_current_master,
                calc_co_pub_fee_based_on_current_sync: clearanceInfo?.calc_co_pub_fee_based_on_current_sync,
                calc_master_co_master_based_on_current_sync: clearanceInfo?.calc_master_co_master_based_on_current_sync,
                licensors_list:formattedLic
             }
             console.log(postJSON,"postJSON")
             this.postLicensor(postJSON);
        }
    }
    postLicensor(postJSON){
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + '/clearanceLicensorsFee', postJSON, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
        .then((response) => {
            if (!response?.data?.error) {
                this.props?.notificationComponent(true, "success");              
                setTimeout(()=>{                        
                    if (!this.props?.isMainTitle) {
                        this.props?.toggleRefreshPage('EpisodeClearance')
                    } else {
                        this.props?.toggleRefreshMainTitleLicensor()
                    }
                },1000);                    
               
            } else {
                this.props?.notificationComponent(true, "fail");
            }

        }, (err) => {
            this.props?.notificationComponent(true, "fail");
            console.log("Failed");
        });
    }
    calculateLicShareAmount(lic, share, initFee, mainFee) {
        let mainShare = this.state.lic?.share;
        let totalFee = mainFee;
        let mainLic = 100;
        // calculate share based on initFee 
        if (!initFee && lic) {
            return (parseFloat(totalFee) * parseFloat(lic) / parseFloat(mainLic))?.toFixed(3);
        }
        // calculate INIT fee
        else if (!initFee && share) {
            return (parseFloat(totalFee) * parseFloat(share) / parseFloat(mainShare))?.toFixed(3);
        }
        else {
            return null;
        }
    }

    postEmailAllow=(identifier)=>{
        let postObj = {
            "show_season_episode_id": this.props.show_season_episode_id,
            "song_id": !this.props?.isMainTitle ? this.state.selectedSong?.song_id : this.state.selectedSong?.song_id,
            "show_id": this.props.showId,
            // "letter_type": "restrictAllPromoUse"
            }
        if(identifier === "restrictAllPromoUse"){
            this.setState({isSubmit: true})
            postObj["letter_type"] = "restrictAllPromoUse"
        }else if(identifier === "LimitedPromoUse"){
            this.setState({isLimitedBtnLoader: true})
            postObj["letter_type"] = "AllowPromoOnlyEpisode"
        }else{
            this.setState({isAllowBtnLoader: true})
            postObj["letter_type"] = "AllowAllPromo"
        }
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/sendEmail`,
            postObj,
            this.props?.userContext?.active_tenant?.tenant_id,this.props?.division_id
          ).then(
            (response) => {
              if (response.data.data.error) {
                if(identifier === "restrictAllPromoUse"){
                    this.setState({isSubmit: false})
                }else if(identifier === "LimitedPromoUse"){
                    this.setState({isLimitedBtnLoader: false})
                }else{
                    this.setState({isAllowBtnLoader: false})
                }
                this.props?.notificationComponent(true, "fail");
              } else {
                if(identifier === "restrictAllPromoUse"){
                    this.setState({isSubmit: false})
                }else if(identifier === "LimitedPromoUse"){
                    this.setState({isLimitedBtnLoader: false})
                }else{
                    this.setState({isAllowBtnLoader: false})
                }
                this.props?.notificationComponent(true, "success");
              }
            },
            (err) => {
              this.setState({ isSubmit: false });
              this.props?.notificationComponent(true, "fail");;
            }
          );

    }

    getClearanceData = (clearance_id) => {
        if(clearance_id){
        this.setState({ isDataLoading: true });
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/clearance?clearance_id=${clearance_id}`, this.props.userContext?.active_tenant?.tenant_id, this.state.licensorsInfo[0]?.division_id)
            .then(response => {
                let clearanceList = response?.data[0]
                // let sondDonotArr = clearanceList?.songs_list?.map(item => item?.is_do_not_use)
                // let songListData=clearanceList?.songs_list
                this.setState({
                    clearanceRecordData: clearanceList, isDataLoading: false
                })
            },
                (err) => {
                    console.log("Error in fetching entities:", err)
                    this.setState({ isDataLoading: false });
                })
            }
    }

    restrictSongPromo = (index) => {
        songIndex = index
        let licensorsInfo = this.state.licensorsInfo
        licensorsInfo[0].songs_list[songIndex].song_restricted_loading = true
        restrictMessage = this.state.licensorsInfo[0]?.songs_list?.[songIndex]?.restricted_message
        this.setState({ restrictSong: !this.state.restrictSong, licensorsInfo: licensorsInfo })
    }

    handleSongPost = () => {
        let postJson;
        postJson = JSON.parse(JSON.stringify(this.state.licensorsInfo));
        postJson = JSON.parse(JSON.stringify(postJson[0].songs_list[songIndex]));
        this.setState({ postInitiated: true });
        postJson.is_all_promo_use_restricted = postJson.is_all_promo_use_restricted === 1 ? 0 : 1 
        postJson.song_to_be_updated = 1
        delete postJson?.writer
        this.setState({ isSubmit: true })
        if (postJson.is_all_promo_use_restricted === 1) {
            this.postEmailAllow("LimitedPromoUse")
        }
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/songDetails`, postJson, this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false, postInitiated: false });
                    this.props?.notificationComponent(true, "fail");
                } 
                else {
                        this.setState({ isSubmit: false, postInitiated: false },() => {
                            this.handleClearancePost();
                        });
                }    
            }, (err) => {
                this.setState({ isSubmit: false, postInitiated: false });
                this.props?.notificationComponent(true, "fail");
            })
    }
        
    handleClearancePost = () => {
        let postJson = this.state.clearanceRecordData
        postJson.is_all_promo_use_restricted = postJson?.is_all_promo_use_restricted === 1 ? 0 : 1
        let extensionUrl = '/clearance'
        this.setState({ isSubmit: true })
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id)
            .then((response) => {
                if (!response.data.error) {
                    this.setState({ isSubmit: false, postInitiated: false }, () => {
                        setTimeout(()=>{                        
                            if (!this.props?.isMainTitle) {
                                this.props?.toggleRefreshPage('EpisodeClearance')
                            } else {
                                this.props?.toggleRefreshMainTitleLicensor()
                            }
                        },1000);
                        this.props?.notificationComponent(true, "success")
                    })
                }
                else {
                    this.setState({ isSubmit: false, postInitiated: false })
                    this.props?.notificationComponent(true, "fail")
                }
            },
                (err) => {
                    this.setState({ isSubmit: false, postInitiated: false })
                    this.props?.notificationComponent(true, "fail")
                    console.log("Post agency details error: " + err);
                });
    }

    saveSongAndClearance = () => {
        this.setState({ restrictSong: false }, () => {
            this.handleSongPost();
        })
    }

    render() {
        let commonPermissions = this.props?.userContext?.active_tenant?.common_permissions;
        songModalPermissions = this.props?.userContext?.active_tenant?.common_permissions?.find(item => item.permission_group_name == "Songs")
        let songAndPerformerPermissions = this.props?.isMainTitle ? this.props?.songAndPerformerPermissions?.find(item => item.permission_group_name = "SS - Main Title") : this.props?.songAndPerformerPermissions?.find(item => item.permission_group_name != "SS - Main Title");
        let message = (this.state.deleteClearanceRecord || this.state.deleteMainTitleRecord) ? this.state.deleteMessage :"clearance record should have atleast one song";
        let comfirmModalTitle = this.state.deleteClearanceRecord ? this.state.deleteTitle :
        this.state.deleteMainTitleRecord ? this.state.deleteMainTitle :(this.state.restrictSong ? " " : "clearance record should have atleast one song");
        let open = Boolean(this.state.setPopover);
        let id = open ? 'simple-popover' : undefined;
        let syncLicensorSelectOptionList = [...this.state.selectOptionList]?.filter(o => !o?.type || o?.type == "S");
        let masterLicensorSelectOptionList = [...this.state.selectOptionList]?.filter(o => !o?.type || o?.type == "M");
        return (

            <div className="CompositionFiles-Container">
                
                {this.state.deleteClearanceRecordLoading && <div className="ContentLoader">
                    <CircularProgress />
                </div>
                }

                {this.state.licensorsInfo?.map((licensor) =>
                    <div key={licensor?.id}>
                        <MDBRow>
                            <MDBCol md={10} className="border-right">
                                {/* {permissionViewSongInformationAndPerformerName && */}
                                    <>

                                        {/* <div> */}
                                        <MDBRow>
                                            <MDBCol md={1} className="header-title">
                                            {!this.props?.isMainTitle &&
                                                <div>
                                                    <span>
                                                        <MDBIcon
                                                            className='chevron-btn'
                                                            icon={"chevron-circle-left"}
                                                            onClick={() => this.props.handleChangePage()}
                                                        />
                                                    </span>
                                                </div>}
                                            </MDBCol>
                                            <MDBCol md={9}>
                                            {songAndPerformerPermissions?.view == 1 && 
                                            <>
                                            {!this.props?.isMainTitle ?
                                                <MDBRow className="song-header-title">
                                                    <>
                                                    {licensor?.songs_list?.map((song, index) => (
                                                        <div>
                                                            <span
                                                                className={`${this.state.selectedSong?.song_id === song?.song_id ? 'highlight-song' : ''} space`}
                                                                onClick={() => this.handleSongSelection(song)}
                                                            >
                                                                {song?.song_title}
                                                            </span>
                                                            <span className='small-text'>
                                                                {song?.is_do_not_use ? "Do Not Use This Song." : " "}
                                                            </span>
                                                            {/* <span className='small-text'>
                                                                {song?.is_all_promo_use_restricted ? "Restricted Promo Use" : " "}
                                                            </span> */}
                                                           
                                                            {/* {song?.is_limited_promo_use === 1 &&
                                                             <span className='labelButton small-text' onClick={()=>this.postEmailAllow("LimitedPromoUse")}>
                                                               {this.state.isLimitedBtnLoader ? <CircularProgress size={10} color="inherit"/>: "Limited Promo Use"}
                                                            </span>} */}
                                                            { // song?.is_all_promo_use_restricted ===1 &&
                                                             <span className={song?.is_all_promo_use_restricted === 1 ? 'red-restrictedPromoButton small-text' : 'green-restrictedPromoButton small-text'} onClick={() => this.restrictSongPromo(index) }>
                                                                {song?.song_restricted_loading ? <CircularProgress size={10} color="inherit"/>: (song?.is_all_promo_use_restricted === 1 ? "Restricted Promo Use" : "Promo Use allowed")}
                                                            </span>}
                                                            {/* {song?.is_all_promo_use_restricted === 0 && song?.is_limited_promo_use === 0 &&
                                                                <span className='labelButton small-text'  onClick={()=>this.postEmailAllow("AllowallPromoUse")}>
                                                                    {this.state.isAllowBtnLoader ? <CircularProgress size={10} color="inherit"/>: "Allow all Promo Use"}
                                                                </span>} */}
                                                        </div>
                                                    ))}
                                                    </>
                                                </MDBRow>:
                                                <MDBRow className="song-header-title">
                                                     <>
                                                     {licensor?.songs_list?.map(song => (
                                                        <div>
                                                            <span
                                                                className={`${this.state.selectedSong?.song_id === song?.song_id ? 'highlight-song' : ''} space`}
                                                                onClick={() => this.handleSongSelection(song)}
                                                            >
                                                                {song?.song_title}
                                                            </span>
                                                            <span className='small-text'>
                                                                {song?.is_do_not_use ? "Do Not Use This Song." : " "}
                                                            </span>
                                                            {/* <span className='small-text'>
                                                                {song?.is_all_promo_use_restricted ? "Restricted Promo Use" : " "}
                                                            </span> */}
                                                             {song?.is_limited_promo_use ===1 &&
                                                             <span className='labelButton small-text' onClick={()=>this.postEmailAllow("LimitedPromoUse")}>
                                                               {this.state.isLimitedBtnLoader ? <CircularProgress size={10} color="inherit"/>: "Limited Promo Use"}
                                                            </span>}
                                                            {song?.is_all_promo_use_restricted ===1 &&
                                                             <span className='labelButton small-text' onClick={()=>this.postEmailAllow("restrictAllPromoUse")}>
                                                                {this.state.isSubmit ? <CircularProgress size={10} color="inherit"/>: "Restricted Promo Use"}
                                                            </span>}
                                                            {song?.is_all_promo_use_restricted === 0 && song?.is_limited_promo_use === 0 &&
                                                                <span className='labelButton small-text'  onClick={()=>this.postEmailAllow("AllowallPromoUse")}>
                                                                    {this.state.isAllowBtnLoader ? <CircularProgress size={10} color="inherit"/>: "Allow all Promo Use"}
                                                                </span>}
                                                        </div>
                                                    ))}
                                                    </>
                                                    </MDBRow>}
                                                {/* <MDBRow>
                                                {this.props?.licensorsInfo[0]?.songs_list[0]?.is_limited_promo_use===1 ?
                                                <MDBCol>
                                                <BasicButton
                                                    text="Limited Promo Use"
                                                    onClick={this.handleShowRestrictPromo}/>
                                                </MDBCol> : null}
                                                {this.props?.licensorsInfo[0]?.songs_list[0]?.is_all_promo_use_restricted ===1 ?
                                                <MDBCol>
                                                    <BasicButton
                                                    text="Restricted Promo Use"
                                                    onClick={this.handleShowRestrictPromo}
                                                    />
                                                </MDBCol>:null}
                                                </MDBRow> */}
                                                <MDBRow>
                                                {this.state.config.map(item => (
                                                    this.state.selectedSong?.[item.dataNode] === 1 ? 
                                                        <>
                                                            <MDBCol className="btn-circle song-details-margin ml-1 space-2">        
                                                                <div className="btn-text">
                                                                    <div className="cancel-icon">
                                                                        <span className="details-label">{item.label}</span>                                                                           
                                                                        <span>
                                                                            <MDBIcon
                                                                                className={this.state.selectedSong?.[item.dataNode] === 1 ? "check-icon" : "cancle-icon"}
                                                                                icon={this.state.selectedSong?.[item.dataNode] === 1 ? "check-circle" : "times-circle"}
                                                                            />
                                                                        </span>    
                                                                    </div>
                                                                </div>
                                                            </MDBCol>
                                                        </>
                                                    : "" ))}
                                                </MDBRow>
                                                </>
                                            }
                                            </MDBCol>
                                            {/* {permissionAddChangeSong && */}
                                                <MDBCol md={2} className="btn-song2">
                                                    <div className="btn-song">
                                                <BasicButton
                                                    // variant="text"
                                                     color="error"
                                                    // type={"inline"}
                                                    text={" Delete "}
                                                    icon={"trash-alt"}
                                                    iconRight={false}
                                                    className={"clsEnable delClr delClrBtn clsDisable   delClrBtn "}
                                                    // disabled={(this.state?.clearanceRecordData?.in_batch  && this.state?.clearanceRecordData?.in_batch?.toLowerCase() != 'no') ? true : false}
                                                    onClick={this.handleDeleteConfirmation}
                                                />
                                                        <BasicButton
                                                            variant="contained"
                                                            type="inline"
                                                            color="primary"
                                                            text={"Song"}
                                                            icon={"angle-down"}
                                                            iconRight={true}
                                                            onClick={songAndPerformerPermissions?.edit == 1 ? this.handleClick : null}
                                                        />
                                                        <div className="popover-text">
                                                            <Popover
                                                                id={id}
                                                                open={open}
                                                                className={"popover-text"}
                                                                anchorEl={this.state.setPopover}
                                                                getContentAnchorEl={null}
                                                                onClose={this.handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                PaperProps={{ style: { width: '18%', backgroundColor: '#F2F2F2', borderRadius: '1px' } }}
                                                            >
                                                                {this.state.selectSongList.map((item, index) => {
                                                                    return (
                                                                        <div
                                                                            className="popover-content-value"
                                                                            onClick={() => this.handleSongOptionClick(item.label)}
                                                                        >
                                                                            {item.label}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                            {/* } */}
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <div className="header-text"> Performers</div>
                                                <div className="details-para">{this.state.selectedSong?.performer_name}</div>
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <div className="header-text"> Writers</div>
                                                <div className="details-para">{this.state.selectedSong?.writer?.map((item,index, writers) => `${item?.writer_name}${index === writers.length - 1 ? '' : ', '}` )}</div>
                                                {/* <div className="details-para">{this.state.selectedSong?.writer?.map((item,index, writers) => `${item?.writer_name}${item?.affiliation_name ? '-'+item?.affiliation_name  : ''} ${item?.affiliation_name ? item?.share :''}${index === writers.length - 1 ? '' : ', '}` )}</div> */}
                                            </MDBCol>
                                            </MDBRow>
                                        <MDBRow className="mt-2">
                                            <Details
                                                licensorsInfo={this.state.licensorsInfo}
                                                selectedSong={this.state.selectedSong}
                                                isMainTitle={this.props?.isMainTitle || false}
                                                division_id={this.props.division_id}
                                                mainTitleId={this.props?.mainTitleId}
                                                show_season_id={this.props?.showSeasonId}
                                                showId={this.props?.showId}
                                                seasonId={this.props?.seasonId}
                                                show_name={this.props?.show_name}
                                                season_name={this.props?.season_name || this.state.licensorsInfo?.[0]?.season_name}
                                                clearance_rep_id={this.props?.clearance_rep_id}
                                                clearance_rep={this.props?.clearance_rep}
                                                episodeLength={this.props?.episodeLength}
                                                clearance_divisionId={this.state.licensorsInfo[0]?.division_id }
                                                // permissionEditASong={permissionEditASong}
                                                //toggleRefreshPage={this.props?.toggleRefreshPage}
                                                // commonPermissions={!(this.props?.isMainTitle) ? this.props?.permissionArrClearanceRecords : this.props?.permissionArrMainTitile}
                                                toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                                                applyHVPermission={this.props?.applyHVPermission}
                                                songAndPerformerPermissions={songAndPerformerPermissions}
                                                mainTitlePermissions={this.props?.mainTitlePermissions}
                                                clearancePermissions={this.props.clearancePermissions}
                                                episodeSongPermissions={this.props?.episodeSongPermissions}
                                            />
                                        </MDBRow>
                                        {/* </div> */}

                                    </>
                                {/* } */}
                            </MDBCol>
                            <MDBCol md={2}>
                                {!this.props?.isMainTitle &&
                                    <MDBRow>
                                        <div className="Completed">
                                            <span className="header-text">Clearance ID </span>
                                            <span className="header-text-value">{this.props?.clearenceId}</span>
                                        </div>
                                    </MDBRow>
                                }

                                <MDBRow>
                                    <div className="Completed">
                                        <span className="header-text">Total Init. Fee </span>
                                        <span className="header-text-value">{this.state.totalInitFee ? `- $ ${this.state.totalInitFee}` : '-'}</span>
                                    </div>
                                </MDBRow>
                                <MDBRow>
                                    <div className="Completed">
                                        <span className="header-text">Total Init. Master Fee </span>
                                        <span className="header-text-value">{this.state.masterInitFee ? `- $ ${this.state.masterInitFee}` : '-'}</span>
                                    </div>
                                </MDBRow>
                                <MDBRow>
                                    <div className="Completed Completed2">
                                        <span className="header-text">Total Init. Sync Fee </span>
                                        <span className="header-text-value">{this.state.syncInitFee ? `- $ ${this.state.syncInitFee}` : '-'}</span>
                                    </div>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        {!this.state.clearneceLicenserInfo?.[0]?.licensors_list?.length > 0 &&
                            <BasicButton
                                variant="contained"
                                type="inline"
                                color="primary"
                                text="Add a Licensor"
                                onClick={this.addNewLicensorInfo}
                            />
                        }
                        {this.state.showCalcFeesError && <><div className='errorText'>Can not perform calculation as check request amount already paid</div><br/></>}
                        {this.state.clearneceLicenserInfo?.[0]?.licensors_list?.map(
                            (item, index) =>
                                <>
                                    <MDBRow className="m-0">
                                        <SyncLicensor {...this.props}
                                            key={"lic" + item.licensor_id}
                                            isMainTitle={this.props?.isMainTitle || false}
                                            syncLicensorConfig={!this.props.isMainTitle ? this.state.syncLicensorConfig : mainTitlesyncLicensorConfig}
                                            syncLicensorList={!this.props?.isMainTitle ?
                                                item.clearance_check_request :
                                                item.main_title_check_request
                                            }
                                            season_name={this.props?.season_name ?  this.props?.season_name : this.state.licensorsInfo?.[0]?.season_name}
                                            episode_number = {this.props?.episode_number}
                                            selectOptionList={item?.licensor_type == "S" ? syncLicensorSelectOptionList : item?.licensor_type == "M" ? masterLicensorSelectOptionList : this.state?.selectOptionList}
                                            totalMasterLicensor={this.state.totalMasterLicensor}
                                            totalSyncLicensor={this.state.totalSyncLicensor}
                                            calcFeesBasedOnSyncMaster={this.calcFeesBasedOnSyncMaster.bind(this)}
                                            syncIconList={syncIconList}
                                            selectedSong={this.state.selectedSong}
                                            show_name={this.props?.show_name}
                                            optionalRightsConfig={optionalRightsConfig}
                                            // optionalRightsList={item.clearanceOptCheckRequests}
                                            CueSheetPublisherList={item?.cue_sheet_publishers}
                                            CueSheetPublisherConfig={CueSheetPublisherConfig}
                                            handleGeneralEdit={this.props.handleGeneralEdit}
                                            handleListEdit={this.props.handleListEdit}
                                            selectLicensorType={selectLicensorType}
                                            handleCopyLicensor={this.props.handleCopyLicensor}
                                            // clearneceLicenserInfo={this.state.clearneceLicenserInfo}
                                            clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                                            lic={item}
                                            mode={this.state.mode}
                                            setMode={() => {
                                                if (this.props?.isMainTitle ? this.state.clearneceLicenserInfo[0]?.licensors_list[index]?.main_title_licensor_id : this.state.clearneceLicenserInfo[0]?.licensors_list[index]?.clearance_licensor_id) {
                                                    this.props?.setMode(null, index)
                                                } else {
                                                    let removeableList = [...this.state.clearneceLicenserInfo]
                                                    removeableList[0]?.licensors_list?.splice(index, 1)
                                                    this.setState({ clearneceLicenserInfo: removeableList })
                                                }
                                            }}
                                            index={index}
                                            division_id={this.props.division_id}
                                            showSeasonId={this.props.showSeasonId}
                                            showId={this.props.showId}
                                            seasonId={this.props.seasonId}
                                            showSeasonEpisodeId={this.props.show_season_episode_id}
                                            clearenceId={this.props.clearenceId}
                                            licensorsInfo={this.props?.licensorsInfo}
                                            mainTitleId={this.props?.mainTitleId}
                                            mainTitleSongId={licensor?.songs_list?.[0]?.main_title_song_id}
                                            addNewLicensorInfo={this.addNewLicensorInfo}
                                            status={this.state.status}
                                            song_id={this.props?.song_id}
                                            licensorListData={this.state.clearneceLicenserInfo?.[0]?.licensors_list}
                                            toggleRefreshPage={this.props?.toggleRefreshPage}
                                            // permissionArrClearanceRecords={!this.props?.isMainTitle ? this.props?.permissionArrClearanceRecords : this.props?.permissionArrMainTitile}
                                            toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                                            clearancePermissions={this.props.clearancePermissions}
                                            EmailLetterPermission={this.props?.EmailLetterPermission}
                                            mainTitlePermissions={this.props?.mainTitlePermissions}
                                            clearanceBatchesPermissions={this.props?.clearanceBatchesPermissions}
                                            batchesPermissions={this.props?.batchesPermissions}
                                            changeStatusOfLicensors={this.changeStatusOfLicensors}
                                        />
                                    </MDBRow>
                                </>
                        )}
                        <br />
                        {
                            this.state.showEditSongModal &&
                            <MainViewModalComponent
                                open={this.state.showEditSongModal}
                                handleClose={this.handleHideShowEditSongModal}
                                headerText={"Song"}
                                mode={"Edit"}
                                modalName={"Song"}
                                songId={!this.props?.isMainTitle ? this.state.selectedSong?.song_id :
                                    this.state.selectedSong?.song_id
                                }
                                isMainTitle={this.props?.isMainTitle}
                                // commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
                                toggleRefreshPage={this.props?.toggleRefreshPage}
                                division_id={this.props?.division_id}
                                toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                                commonPermissions={commonPermissions}
                            />
                        }
                        {
                            this.state.showEditClearanceModal && (
                                this.props?.isMainTitle ?
                                    <MainViewModalComponent
                                        open={this.state.showEditClearanceModal}
                                        handleClose={() => this.setState({ showEditClearanceModal: false })}
                                        headerText={'Main Title Clearance Record'}
                                        mode={"Edit"}
                                        modalName={"Main Title Clearance Record"}
                                        isMainTitle={this.props?.isMainTitle}
                                        mainTitleId={this.props?.mainTitleId}
                                        show_season_id={this.props?.showSeasonId}
                                        showId={this.props?.showId}
                                        seasonId={this.props?.seasonId}
                                        division_id={this.props?.division_id}
                                        show_name={this.props?.show_name}
                                        season_name={this.props?.season_name}
                                        clearance_rep_id={this.props?.clearance_rep_id}
                                        clearance_rep={this.props?.clearance_rep}
                                        episodeLength={this.props?.episodeLength}
                                        updateMainTitleDetails={(details) => this.setState({ licensorsInfo: details })}
                                        toggleRefreshPage={this.props?.toggleRefreshPage}
                                        toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                                        mainTitlePermissions={this.props?.mainTitlePermissions}
                                        notificationComponent={this.props?.notificationComponent}
                                        episodeSongPermissions={this.props?.episodeSongPermissions}
                                    /> :
                                    <MainViewModalComponent
                                        open={this.state.showEditClearanceModal}
                                        handleClose={() => this.setState({ showEditClearanceModal: false })}
                                        headerText={"Clearance Record"}
                                        mode={"Edit"}
                                        modalName={"Clearance Record"}
                                        clearenceId={this.state.clearenceId}
                                        clearance_divisionId={this.state.clearance_divisionId}
                                        toggleRefreshPage={this.props?.toggleRefreshPage}
                                        clearancePermissions={this.props.clearancePermissions}
                                        notificationComponent={this.props?.notificationComponent}
                                        viewNewCRPermission={this.props.clearancePermissions?.find((per) => clearanceRecordPermissionsConfig.find((item) => item.buttonName == "New CR" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)))}
                                        episodeSongPermissions={this.props?.episodeSongPermissions}
                                        // commonPermissions={!(this.props?.isMainTitle) ? this.props?.permissionArrClearanceRecords : this.props?.permissionArrMainTitile}
                                    />

                            )}
                        {
                            this.state.showNewSongModal &&
                            <MainViewModalComponent
                                open={this.state.showNewSongModal}
                                handleClose={() => this.setState({ showNewSongModal: false })}
                                headerText={"Song"}
                                mode={"New"}
                                modalName={"Song"}
                                toggleRefreshPage={this.props?.toggleRefreshPage}
                                commonPermissions={commonPermissions}
                            />
                        }
                        {
                            this.state.restrictedPromo &&
                            <MainViewModalComponent
                                open={this.state.restrictedPromo}
                                handleClose={this.handleHideRestrictPromo}
                                headerText={"Restricted Promo"}
                                mode={""}
                                modalName={"AllowRestrictUse"}
                            />
                        }
                        <MessageModal
                            open={this.state.showError || false}
                            title={comfirmModalTitle}
                            message={message}
                            hideCancel={false}
                            primaryButtonText={"OK"}
                            secondaryButtonText={"Cancel"}
                            onConfirm={() => this.onConfirm()}
                            handleClose={() => this.onCancel()} />
                        <MessageModal
                            open={this.state.restrictSong || false}
                            title={comfirmModalTitle}
                            message={restrictMessage}
                            hideCancel={false}
                            primaryButtonText={"Yes"}
                            secondaryButtonText={"No"}
                            onConfirm={() => this.saveSongAndClearance()}
                            handleClose={() => this.onRestrictCancel()} />
                    </div>
                )}
            </div>
        )
    }
}
);